.App {
  color: #0d0d0e;
  text-align: center;
  background-color: #ffffff;
  /* background-image: url("./images/10.png");
  background-repeat: no-repeat; 
  background-size: cover; */
}

.hero.is-light {
  background-color: #21212457;

  /* background-color: #1e1f2c; */
}
.footer {
  background-color: #18181a;
  height: 100px !important;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.App-header {
  background-color: #161618;
  color: white;
}
.navbar-item {
  color: #dfdfdf;
  font-weight: 300;
  letter-spacing: 0.02em;
}

a.navbar-item:hover {
  background: none;
  color: white;
}
.hero.is-light .title,
.hero.is-light .subtitle {
  color: white;
}
.hero-body {
  height: 220px;
}
.main {
  padding-bottom: 100px;
  /* background-color: #ffffff !important; */
  /* background-color: rgb(20, 20, 22); */
  background: url("./noise.png");
  background-repeat: repeat;
  background-size: 110px 110px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
  color: white;
}

.logo {
  font-weight: 400 !important;
  letter-spacing: 0.00005em !important;
  font-size: 18px;
}
.navbar {
  background-color: transparent;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
p strong,
h3 {
  color: white;
  font-weight: 300;
}
p {
  color: #b9b9b9;
}
.mainContainer,
.box {
  height: 100%;
  background-color: #1d1d2050;
  border-radius: 9px;
  padding: 20px 20px 20px 20px;
  margin: 30px 0px 30px 0px;
}

h2 {
  font-weight: 300;
}
.link {
  cursor: pointer;
  transition: transform 0.3s; /* Animation */
  background: #0f0f0f !important;
  /* border: 1px rgba(58, 58, 58, 0.075) solid; */
}
::placeholder {
  color: white !important;
}
.input {
  background-color: #202020;
  color: white;
  border-color: rgb(43, 43, 43);
}

.button {
  background-color: rgb(233, 37, 70) !important;
  border-color: #3d3d3d !important;
  color: white;
}

.input:hover {
  border-color: rgb(233, 37, 70);
}

.button:hover {
  background-color: rgb(168, 34, 56) !important;
  color: white !important;
}
.red {
  color: rgb(233, 37, 70) !important;
  font-size: 23px !important;
}
.navbar {
  padding: 0px 16% 0px 16%;
}
.link:hover {
  transform: scale(1.04);

  /* border: 1px rgba(58, 58, 58, 0.075) solid; */
}
.hoverTile {
  transition: opacity 0.3s;
}
.hoverTile:hover {
  opacity: 0.3;
}

.titleCategory {
  text-align: left;
  margin: 40px 0px 0px 0px;
  color: white !important;
  font-size: 18px;
  font-weight: 500;
}

.titleCategory:hover {
  color: white !important;
}
hr.solid {
  border-top: 1px solid transparent;
  background: none !important;
}
.notification {
  background-color: transparent;
  padding: 0px;
}
.featuredContainer {
  background-color: #222222;
  border-radius: 8px;
  border: 1px rgba(58, 58, 58, 0.075) solid;
}

progress::-webkit-progress-bar {
  background-color: #212124 !important;
  width: 100%;
}
.redHover:hover {
  color: rgb(233, 37, 70) !important;
}

.redHover {
  text-align: left;
  color: #afafaf !important;
}

progress::-webkit-progress-value {
  background-color: rgb(233, 37, 70) !important;
}

.smallContainer {
  margin-top: 10px;
  vertical-align: middle;
  width: 60px;
  height: 20px;
  float: right;
  background-color: #2b2b2b;
  border-radius: 3px;

  font-size: 11px;
  font-weight: 600;
}

.smallContainer > p {
  padding-top: 2px;
  color: rgb(233, 37, 70) !important;
}

a {
  color: rgb(233, 37, 70);
}

a:hover {
  color: rgb(233, 37, 70);
}
.containerTransparent {
  position: relative;
  float: left;
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.columns {
  padding: 10px !important;
}

.smallVideo {
  position: absolute;
  right: 35px;
}

.navbar {
  width: 100%;
}

.container {
  width: 900px;
  max-width: none;
  background-color: rgb(255, 255, 255);
  height: 150px;
  padding: 15px;
  border-radius: 8px;
  border: 1px rgba(58, 58, 58, 0.075) solid;
}
.App-header {
  /* background-color: #1b1c1f; */
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(22, 21, 27);
}
.title {
  color: white;
  letter-spacing: 0.09em;
  font-size: 0.7em !important;
}

.titleRead {
  color: white;
  letter-spacing: 0.09em;
  font-size: 0.6em !important;
}

.titleRecommanded {
  color: white;
  letter-spacing: 0.09em;
  font-size: 14px !important;
}

.titleWatch {
  color: white;
  letter-spacing: 0.1em;
  font-size: 1.2em !important;
}
.titleHero {
  color: white;
  letter-spacing: 2em;
  text-transform: uppercase;
  font-size: 0.9em !important;
}

.subtitle {
  color: #c2c2c2;
}

.main {
  /* background-color: #1b1c1f; */

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1.5vmin);
  color: rgb(27, 27, 27);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
